
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
    widgetColor: String,
    chartHeight: Number
  },
  data() {
    return {
      report: {},
      mainColor: "primary"
    };
  },
  methods: {
    initData(book_id) {
      ApiService.post("Mock/MyReportCard", { book_id } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            if (data.data) {
              this.report = data.data;
              this.mainColor = ((this.report as any).is_pass == '1')?"danger":"warning";
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    }
  },
  mounted() {
    //
  },
  setup() {
    const items = [
      {
        icon: "media/icons/duotune/maps/map004.svg",
        title: "Sales",
        description: "100 Regions",
        stats: "$2,5b",
        arrow: "up"
      },
      {
        icon: "media/icons/duotune/general/gen024.svg",
        title: "Revenue",
        description: "Quarter 2/3",
        stats: "$1,7b",
        arrow: "down"
      },
      {
        icon: "media/icons/duotune/electronics/elc005.svg",
        title: "Growth",
        description: "80% Rate",
        stats: "$8,8m",
        arrow: "up"
      },
      {
        icon: "media/icons/duotune/general/gen005.svg",
        title: "Dispute",
        description: "3090 Refunds",
        stats: "$270m",
        arrow: "down"
      }
    ];

    return {
      items
    };
  }
});
