
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import Bus from "@/common/ts/Bus";
import MockEdit from "@/views/mock/MockEditModal.vue";
import MockReport from "@/views/mock/MockReport.vue";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    MockEdit,
    MockReport
  },
  data() {
    return {
      path: "",
      book_id: "",
      list: [] as any,
      mock_status: "",
      //分页
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      tableData: []
    };
  },
  methods: {
    handleReport() {
      (this.$refs.report as any).initData(this.book_id);
    },

    handleView() {
      this.$router.push({
        path: "/mock/start",
        query: { book_id: this.book_id, is_preview: "1", path: this.path }
      });
    },

    handleStart() {
      let book_id = this.book_id;
      let rule_list = [] as any;
      this.list.forEach((item) => {
        let row = {
          question_type: item.question_type,
          per_score: item.per_score,
          easy_count: item.easy_count,
          normal_count: item.normal_count,
          difficult_count: item.difficult_count
        };
        rule_list.push(row);
      });

      const data = {
        book_id: book_id,
        rule_list: rule_list
      };
      let resource = "Mock/MockReady";
      ApiService.post(resource, data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.$router.push({
              path: "/mock/start",
              query: { book_id: this.book_id, path: this.path }
            });
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    submitClear() {
      let book_id = this.book_id;
      const data = {
        book_id: book_id
      };
      let resource = "Mock/MockClear";
      ApiService.post(resource, data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.handleStart(); //重新开始
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    handleReset() {
      Toast.confirm("请确定是否删除之前数据，重新开始？", () => {
        this.submitClear();
      });
    },

    handleEdit(item, index) {
      (this.$refs.MockEdit as any).initData(item, index);
    },

    handleSetItem(e) {
      const item = JSON.parse(e.item);
      const index = e.index;
      this.list[index] = item;
    },

    loadData() {
      const data = {
        book_id: this.book_id
      };
      let resource = "Mock/QuestionCount";
      ApiService.post(resource, data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.mock_status = data.data.mock_status; //赋值1
            let questionCountList = data.data.list;
            let list: any[] = [];
            questionCountList.forEach((item) => {
              let easy_count_list: any[] = [];
              for (let i = 0; i <= item.easy_total_count; i++) {
                easy_count_list.push(i);
              }
              let normal_count_list: any[] = [];
              for (let i = 0; i <= item.normal_total_count; i++) {
                normal_count_list.push(i);
              }
              let difficult_count_list: any[] = [];
              for (let i = 0; i <= item.difficult_total_count; i++) {
                difficult_count_list.push(i);
              }
              let data = {
                //section 1
                question_type: item.question_type,
                question_type_name: item.question_type_name,
                //section 2
                easy_total_count: item.easy_total_count,
                normal_total_count: item.normal_total_count,
                difficult_total_count: item.difficult_total_count,
                //section 3
                per_score: item.per_score,
                easy_count: item.easy_count,
                normal_count: item.normal_count,
                difficult_count: item.difficult_count,
                //section 4
                easy_count_list: easy_count_list,
                normal_count_list: normal_count_list,
                difficult_count_list: difficult_count_list
              };
              list.push(data);
            });

            this.list = list; //赋值2
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    initBus() {
      Bus.$on("handleBack", () => {
        this.$router.push({
          path: "/exercise/ready",
          query: { book_id: this.book_id, path: this.path }
        });
      });

      Bus.$on("handleSetItem", (e) => {
        this.handleSetItem(e);
      });
    }
  },
  beforeUnmount() {
    Bus.$off("handleSetItem");
  },
  mounted() {
    setCurrentPageBreadcrumbs("试卷配置", ["模拟考试"]);
    setCurrentPageButton("back", true);
    this.initBus();
    this.path = this.$route.query.path as string;
    this.book_id = this.$route.query.book_id as string;
    this.loadData();
  }
});
