
import { defineComponent } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Bus from "@/common/ts/Bus";

export default defineComponent({
  name: "new-card-modal",
  components: {},
  data() {
    return {
      show: false,
      loading: false,
      question_count: {},
      ruleForm: [],
      index: 0
    };
  },
  methods:{
    initData(row, index){
      this.ruleForm = row;
      this.index = index;
      this.show = true;
    },
    submitForm(){
      const item = JSON.stringify(this.ruleForm);
      const index = this.index;
      Bus.$emit("handleSetItem",{item, index});
      hideModal(this.$refs.newCardModalRef as any);
    }
  }
  // setup() {
  //   const submitButtonRef = ref<null | HTMLButtonElement>(null);
  //   const newCardModalRef = ref<null | HTMLElement>(null);

  //   const cardData = ref<CardData>({
  //     nameOnCard: "Max Doe",
  //     cardNumber: "4111 1111 1111 1111",
  //     expirationMonth: "",
  //     expirationYear: "",
  //     cvv: "",
  //   });

  //   const validationSchema = Yup.object().shape({
  //     nameOnCard: Yup.string().required().label("Name"),
  //     cardNumber: Yup.string().required().label("Card number"),
  //     expirationMonth: Yup.string().required().label("Month"),
  //     expirationYear: Yup.string().required().label("Year"),
  //     cvv: Yup.string().required().label("CVV"),
  //   });

  //   const submit = () => {
  //     if (!submitButtonRef.value) {
  //       return;
  //     }

  //     //Disable button
  //     submitButtonRef.value.disabled = true;
  //     // Activate indicator
  //     submitButtonRef.value.setAttribute("data-kt-indicator", "on");

  //     setTimeout(() => {
  //       if (submitButtonRef.value) {
  //         submitButtonRef.value.disabled = false;

  //         submitButtonRef.value?.removeAttribute("data-kt-indicator");
  //       }

  //       Swal.fire({
  //         text: "Form has been successfully submitted!",
  //         icon: "success",
  //         buttonsStyling: false,
  //         confirmButtonText: "Ok, got it!",
  //         customClass: {
  //           confirmButton: "btn btn-primary",
  //         },
  //       }).then(() => {
  //         hideModal(newCardModalRef.value);
  //       });
  //     }, 2000);
  //   };

  //   return {
  //     cardData,
  //     validationSchema,
  //     submit,
  //     submitButtonRef,
  //     newCardModalRef,
  //   };
  // },
});
